:root {
  --color-ackama-green: #194740;
  --color-ackama-gray: #556970;
}

.report-header {
  color: var(--color-ackama-green);
  margin-bottom: 25px;
}

.report-support-hours {
  border: solid 1px black;

  td {
    width: 50%;
  }

  .filled,
  .filled > * {
    background: var(--color-ackama-gray);
    color: white;
  }
}

.report-support-desk-tickets {
  border: solid 1px black;

  td:not(:nth-child(2)) {
    white-space: nowrap;
  }

  .filled > * {
    background: var(--color-ackama-gray);
    color: white;
  }
}

.report-service-metrics {
  border: solid 1px black;

  .filled > * {
    background: var(--color-ackama-gray);
    color: white;
  }
}

@import "../stylesheets/customized_bootstrap";
// The entry point for all SCSS
@import "reset";
@import "elements";

@import "trix/dist/trix";
@import "actiontext";

@import "reports";
@import "home";

// todo: this probably shouldn't go here,
//   but I'm really not a fan of the default bold...
th {
  font-weight: normal;
}

/*
 * We need to override trix.css’s image gallery styles to accommodate the
 * <action-text-attachment> element we wrap around attachments. Otherwise,
 * images in galleries will be squished by the max-width: 33%; rule.
*/
.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content
  .attachment-gallery.attachment-gallery--2
  > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment,
.trix-content
  .attachment-gallery.attachment-gallery--4
  > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}

// *********************************************************************************

trix-toolbar {
  border-bottom: 0;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background: white;

  .trix-button-row {
    margin: 5px;

    .trix-button-group {
      margin-bottom: 0;
      border: 0;

      .trix-button {
        margin-bottom: 0;
        border: 0;
      }

      &.trix-button-group--file-tools,
      &.trix-button-group--history-tools {
        display: none;
      }
      &.trix-button-group--block-tools {
        margin: 0;
      }
    }
  }
}

trix-toolbar + trix-editor.form-control {
  border-top: 0;
  border-radius: 0 0 $border-radius $border-radius;
  overflow-y: auto;
  height: 30rem;
}

trix-toolbar,
trix-editor {
  border: $input-border-width solid $input-border-color;
  background: $white;

  &[disabled] {
    background: rgba($gray-500, 0.8);
    color: transparent;
  }
}

// Use intuitive box-sizing.
html {
  box-sizing: border-box;
}

// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*::before,
*::after {
  box-sizing: inherit;
}
